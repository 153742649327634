import React from 'react';
import { Link, graphql } from 'gatsby';
import Image from 'gatsby-image';
import format from 'date-fns/format';

const BlogPost = ({ data }) => {
  const { title, body, date, imageMultiple } = data.contentfulBlogpost;

  return (
    <div className="contentfulBlogPost">
      <div className="titleBlog">{title}</div>
      {/* <div className="dateBlog">{format(new Date(date), 'do MMMM yyyy')}</div> */}
      <div className="blogMainImage">
        <Image fluid={imageMultiple[0].fluid} alt={title} key={title} />
      </div>
      <div
        className="body-text"
        dangerouslySetInnerHTML={{
          __html: body.childMarkdownRemark.html,
        }}
      ></div>
      <div className="linkToNews">
        <Link to="/news">Back to News</Link>
      </div>
      <div className="div8"></div>
      <div className="div9"></div>
    </div>
  );
};
export default BlogPost;

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulBlogpost(slug: { eq: $slug }) {
      id
      title
      date
      body {
        childMarkdownRemark {
          html
        }
      }
      tags
      imageMultiple {
        file {
          url
        }

        title
        description
        fluid(quality: 100, maxHeight: 900, maxWidth: 1200) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`;
